<template>
  <div class="c-language-selector">
    <Popover
      v-model="isDropdownVisible"
      classes="c-language-selector__dropdown"
      :placement="'top-start'"
      :container="'.c-language-selector'"
      :auto-close="true"
      :auto-close-delay="20000"
      :offset="2"
    >
      <button
        class="c-service-menu__button"
        :title="model.activeLanguageName"
        @click.prevent="languageOpen"
      >
        {{ model.activeLanguageCode }}
        <Icon
          class="u-ml--xs"
          :icon="iconArrowDown"
          :size="8"
          :fill="'black'"
        />
      </button>
      <template slot="popover">
        <div class="c-language-selector__list">
          <ul class="u-bare-list">
            <li
              v-for="language in model.languages"
              :key="language.title"
            >
              <a
                class="c-language-selector__link"
                :href="language.url"
                @click="trackLanguage(language.title)"
              >
                <span
                  class="c-radio"
                  :class="{
                    'is-active': language.isActive
                  }"
                >
                  <span class="c-radio__label">{{ language.title }}</span>
                  <span class="c-radio-icon">
                    <Icon
                      :icon="iconCheck"
                      fill="white"
                    />
                  </span>
                </span>
              </a>
            </li>
          </ul>
        </div>
      </template>
    </Popover>
  </div>
</template>

<script>
import iconArrowDown from '@ds/svg/icons/bold/arrow-down-1-bold.svg'
import iconCheck from '@ds/svg/icons/bold/check-1-bold.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import Popover from '@/CVI/WebCore/components/Popover.vue'
import tracker from '@/CVI/WebCore/core-tracker'

const header = document.getElementsByClassName('c-header')[0]

export default {
  components: {
    Icon,
    Popover
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      iconCheck,
      iconArrowDown,
      isDropdownVisible: false
    }
  },
  watch: {
    isDropdownVisible: (visible) => {
      if (visible) {
        header.classList.add('is-language-selector-opened')
      } else {
        header.classList.remove('is-language-selector-opened')
      }
    }
  },
  methods: {
    trackLanguage(language) {
      tracker.trackLanguageSelection({
        event: 'menuNavigationLanguageSelector',
        language
      })
    },
    languageOpen() {
      this.isDropdownVisible = true
      tracker.trackSimpleEvent({
        event: 'menuNavigationLanguage'
      })
    }
  }
}
</script>
